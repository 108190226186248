<template>
	<v-dialog
		v-model="model"
		persistent
		:width="$vuetify.breakpoint.lgAndUp ? '15vw' : $vuetify.breakpoint.smAndUp ? '40vw' : $vuetify.breakpoint.xs ? '80vw' : '66vw'"
	>
		<v-card color="primary" dark>
			<v-card-text>
				{{ $t('loading_progress') }}
				<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'LoadingDialog',
	props: {
		loading: {
			required: true,
			type: Boolean
		}
	},
	computed: {
		model: {
			get: function () {
				return this.loading
			},
			set: function () {
				// Do nothing
			}
		}
	}
}
</script>
